import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const MainSlider = () => {
  return (
    <div className="swiper-container main-slider">
      <Swiper
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
        speed={1500}
        effect="fade"
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: '.main-btn-next',
          prevEl: '.main-btn-prev',
        }}
      >
        <SwiperSlide>
          <div
            className="banner-inner"
            style={{ backgroundImage: "url(assets/img/banner/9.jpg)" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="banner-content">
                    <div className="top-content">
                      <h5 className="sub-title text-secondary">
                      Make a Difference Today!
                      </h5>
                      <h1 className="title">Repair and Maintain Temples</h1>
                      <p>
                      Help us address the urgent need for temple repairs and maintenance. Your contribution aids in restoring these spiritual landmarks and safeguarding their heritage
                      </p>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn btn-primary btnhover1"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#modalDonate"
                        >
                          <span>Join us</span>
                        
                        </a>
                        <a
                          href="#"
                          className="btn btn-secondary btnhover2"
                        >
                          Learn More <i className="flaticon-right-arrows ms-3" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="banner-media row gx-4">
                    <div className="col-5">
                      <div className="main-img1">
                        <img src="assets/img/temple/3.avif" alt="" />
                      </div>
                      <div className="main-img2">
                        <img src="assets/img/temple/2.avif" alt="" />
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="main-img3">
                        <img src="assets/img/temple/11.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="banner-inner"
            style={{ backgroundImage: "url(assets/img/temple/bg3.jpg)" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="banner-content">
                    <div className="top-content">
                      <h5 className="sub-title text-secondary">
                      Make a Difference Today!
                      </h5>
                      <h1 className="title">Care for Damaged Temples</h1>
                      <p>
                      Be part of our mission to repair and maintain damaged temples. Your support helps ensure these sacred sites are revitalized and continue to inspire and serve.
                      </p>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn btn-primary btnhover1"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#modalDonate"
                        >
                  <span>Join us</span>
                
                        </a>
                        <a
                          href="#"
                          className="btn btn-secondary btnhover2"
                        >
                          Learn More <i className="flaticon-right-arrows ms-3" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="banner-media row gx-4">
                    <div className="col-5">
                      <div className="main-img1">
                        <img src="assets/img/temple/1.avif" alt="" />
                      </div>
                      <div className="main-img2">
                        <img src="assets/img/temple/7.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="main-img3">
                        <img src="assets/img/temple/5.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="banner-inner"
            style={{ backgroundImage: "url(assets/img/temple/bg2.jpg)" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="banner-content">
                    <div className="top-content">
                      <h5 className="sub-title text-secondary">
                      Make a Difference Today!
                      </h5>
                      <h1 className="title">Restore Damaged Temples</h1>
                      <p>
                      Join us in repairing and maintaining our damaged temples. Your support ensures these sacred spaces are restored to their former glory and preserved for future generations.
                      </p>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn btn-primary btnhover1"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#modalDonate"
                        >
                 <span>Join us</span>
             
                        </a>
                        <a
                          href="#"
                          className="btn btn-secondary btnhover2"
                        >
                          Learn More <i className="flaticon-right-arrows ms-3" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="banner-media row gx-4">
                    <div className="col-5">
                      <div className="main-img1">
                        <img src="assets/img/temple/8.jpg" alt="" />
                      </div>
                      <div className="main-img2">
                        <img src="assets/img/temple/9.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="main-img3">
                        <img src="assets/img/temple/4.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="bottom-wrapper">
        <ul className="social-list">
          <li>
            <a href="https://www.facebook.com/">Facebook</a>
          </li>
          <li>
            <a href="https://twitter.com/">Twitter</a>
          </li>
          <li>
            <a href="https://www.instagram.com/">Instagram</a>
          </li>
        </ul>
        <div className="btn-wrapper">
          <div className="main-btn main-btn-prev">
            <i className="fa fa-angle-left" />
          </div>
          <div className="main-btn main-btn-next">
            <i className="fa fa-angle-right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSlider;
