import React from 'react'

const Header = () => {
  return (
    <>
  <header className="site-header mo-left header style-2">
  {/* Main Header */}
  <div className="sticky-header main-bar-wraper navbar-expand-lg">
    <div className="main-bar clearfix ">
      <div className="container-fluid clearfix">
        {/* Website Logo */}
        <div className="logo-header mostion logo-dark">
          <a href="#">
            <h3>Wellcare</h3>
          </a>
        </div>
        {/* Nav Toggle Button */}
        <button
          className="navbar-toggler collapsed navicon justify-content-end"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span />
          <span />
          <span />
        </button>
        {/* Extra Nav */}
        <div className="extra-nav">
          <div className="extra-cell d-flex align-items-center">
            <ul className="nav-link-list">
              <li>
                <a
                  href="javascript:void(0);"
                  className="btn-login text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalLogin"
                >
                  Login
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="btn-login"
                  data-bs-toggle="modal"
                  data-bs-target="#modalLogin"
                >
                  Sign Up
                </a>
              </li>
            </ul>
            <a
              className="btn btn-primary btnhover1"
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#modalDonate"
            >
              <span>Donate Now</span>
              <i className="flaticon-heart ms-3" />
            </a>
            <a href="javascript:void(0);" className="menu-btn">
              <svg
                width={26}
                height={26}
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x={22}
                  y={11}
                  width={4}
                  height={4}
                  rx={2}
                  fill="#003B4A"
                />
                <rect x={11} width={4} height={4} rx={2} fill="#003B4A" />
                <rect x={22} width={4} height={4} rx={2} fill="#003B4A" />
                <rect
                  x={11}
                  y={11}
                  width={4}
                  height={4}
                  rx={2}
                  fill="#003B4A"
                />
                <rect
                  x={11}
                  y={22}
                  width={4}
                  height={4}
                  rx={2}
                  fill="#003B4A"
                />
                <rect width={4} height={4} rx={2} fill="#003B4A" />
                <rect y={11} width={4} height={4} rx={2} fill="#003B4A" />
                <rect
                  x={22}
                  y={22}
                  width={4}
                  height={4}
                  rx={2}
                  fill="#003B4A"
                />
                <rect y={22} width={4} height={4} rx={2} fill="#003B4A" />
              </svg>
            </a>
          </div>
        </div>
        {/* Header Nav */}
        <div
          className="header-nav navbar-collapse collapse justify-content-start"
          id="navbarNavDropdown"
        >
          <div className="logo-header logo-dark">
            <a href="">
             <h3>Wellcare</h3>
            </a>
          </div>
          <ul className="nav navbar-nav navbar navbar-left">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Projects</a>
            </li>
            <li className="sub-menu-down">
              <a href="javascript:void(0);">Get Involved</a>
              <ul className="sub-menu">
                <li>
                  <a href="#">Volunteer</a>
                </li>
                <li>
                  <a href="#">Donate</a>
                </li>
               
              
              </ul>
            </li>
            <li className="sub-menu-down">
              <a href="javascript:void(0);">Our Work</a>
              <ul className="sub-menu">
                <li>
                  <a href="#">Temple Restoration and Repair</a>
                </li>
                <li>
                  <a href="#">Temple Renovation</a>
                </li>
                <li>
                  <a href="#">Temple Cleaning and Maintenance</a>
                </li>
            
              </ul>
            </li>
            <li>
              <a href="#">Contact Us</a>
            </li>
            {/* <li>
              <a href="#">Contact Us</a>
            </li> */}
          </ul>
          <div className="header-bottom">
            <a
              href="javascript:void(0);"
              className="btn btn-light btn-login btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#modalLogin"
            >
              <i className="flaticon-logout me-3" />
              Login / Sign Up
            </a>
            <div className="dz-social-icon">
              <ul>
                <li>
                  <a
                    className="fab fa-facebook-f"
                    href="https://www.facebook.com/"
                  />
                </li>
                <li>
                  <a className="fab fa-twitter" href="https://twitter.com/" />
                </li>
                <li>
                  <a
                    className="fab fa-linkedin-in"
                    href="https://www.linkedin.com/"
                  />
                </li>
                <li>
                  <a
                    className="fab fa-instagram"
                    href="https://www.instagram.com/"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Main Header End */}
</header>


  <div className="contact-sidebar">
    <div className="contact-box">
      <div className="logo-contact logo-dark">
        <a href="/">
          <img src="assets/images/logo.png" alt="" />
        </a>
      </div>
      <div className="m-b50 contact-text">
        <div className="dz-title">
          <h4>About us</h4>
          <div className="dz-separator style-1 text-primary mb-0" />
        </div>
        <p>
          Aliquam erat massa porttitor vel egestas sit tristique ultricies lorem
          aliquet venenatis libero a nulla placerat egestas.
        </p>
        <a href="#" className="btn btn-primary btn-sm">
          READ MORE
        </a>
      </div>
      <div className="dz-title">
        <h4>Contact Info</h4>
        <div className="dz-separator style-1 text-primary mb-0" />
      </div>
      <div className="icon-bx-wraper left">
        <div className="icon-md m-r20">
          <span className="icon-cell">
            <i className="las la-phone-volume" />
          </span>
        </div>
        <div className="icon-content">
          <h5 className="tilte">Call Now</h5>
          <p className="m-b0">
            +91 123 456 7890,
            <br /> +91 987 654 3210
          </p>
        </div>
      </div>
      <div className="icon-bx-wraper left">
        <div className="icon-md m-r20">
          <span className="icon-cell">
            <i className="las la-envelope-open" />
          </span>
        </div>
        <div className="icon-content">
          <h5 className="tilte">Email Now</h5>
          <p className="m-b0">info@gmail.com, services@gmail.com</p>
        </div>
      </div>
      <div className="icon-bx-wraper left">
        <div className="icon-md m-r20">
          <span className="icon-cell">
            <i className="las la-map-marker" />
          </span>
        </div>
        <div className="icon-content">
          <h5 className="tilte">Location</h5>
          <p className="m-b0">15/B Miranda House, New York, US</p>
        </div>
      </div>
    </div>
  </div>
  <div className="menu-close" />

</>
  )
}

export default Header
