import React from 'react'
import BannerSlider from './MainSlier'
import MainSlider from './MainSlier'

const Home = () => {
  return (
 <>
 <div className="main-bnr-one">
<MainSlider/>
</div>

  {/* Donate */}
  <section className="content-inner section-wrapper4">
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
          <div
            className="donate-form form-wrapper"
            style={{
              backgroundImage: 'url("assets/img/banner/11.jpg")',
              backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
            }}
          >
            <div className="donate-info">
              <h2 className="title m-b20">सेवा परमो धर्मः</h2>
              <p>
              अदेशकाले यद्दानमपात्रेभ्यश्च दीयते ।
<br/>
असत्कृतमवज्ञातं तत्तामसमुदाहृतम्‌ ॥
<br/>
<hr/>
जो दान अनुचित स्थान में, अनुचित समय पर, अज्ञानता के साथ, अपमान करके अयोग्य व्यक्तियों को दिया जाता है, उसे तामसी (तमोगुणी) दान कहा जाता है। 
<hr/>
अश्रद्धया हुतं दत्तं तपस्तप्तं कृतं च यत्‌ ।
<br/>
असदित्युच्यते पार्थ न च तत्प्रेत्य नो इह ॥ 
<br/><hr/>



तुलसीदास जी कहते हैं— श्रद्धा और प्रसन्नता पूर्वक दान करने से हमारा धन घटता नहीं बल्कि शुक्ल पक्ष के चंद्रमा की तरह धीरे-धीरे बढ़ता रहता है।
              </p>
            </div>
         
          </div>
        </div>
        <div
          className="col-xl-7 col-lg-6 m-b30 wow fadeInUp"
          data-wow-delay="0.4s"
        >
          <div className="section-inner">
            <div className="section-head m-b30">
              <h5 className="sub-title">About Us</h5>
              <h2 className="title">
              Helping Temples Heal, <br/>One Step at a Time
               
              </h2>
            </div>
            <div className="row m-b30">
              <div className="col-xl-10">
                <p>
                At Wellcare Foundation, we believe that temples are the heart of our communities, offering sacred spaces for worship, peace, and spiritual growth. However, many of these ancient structures have faced the test of time and now stand in need of repair.
                </p>
                <p>
                Through your generous support, we aim to restore these holy sites to their former glory, ensuring that the divine presence continues to bless generations to come. Every contribution, no matter the size, plays a crucial role in healing these sacred spaces. Join us in our mission to revitalize temples and preserve our spiritual heritage, one step at a time. Together, we can make a lasting impact.
                </p>
              </div>
           
            </div>
            <a href="#" className="btn btn-primary">
              Know More
            </a>
          </div>
        </div>
      </div>
    </div>
    <img
      className="img-1"
      src="assets/img/omm.png"
      alt="images"
    />
  </section>
  {/* Donate End*/}
  <section className="clearfix">
  <div className="container-fluid">
    <div
      className="content-inner bg-gray"
      style={{
        backgroundImage: 'url("assets/images/background/bg10.png")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <div className="container">
        <div
          className="section-head text-center wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <h5 className="sub-title">Our Vision</h5>
          <h2 className="title">Our approach to temple restoration and innovation</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <div
              className="icon-bx-wraper text-center style-4 m-b30 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="icon-bx-sm m-b20">
                <a href="#" className="icon-cell">
                 <img src="assets/img/temple.png" alt="temple"></img>
                </a>
              </div>
              <div className="icon-content">
                <div className="separator" />
                <h5>Identify dilapidated temples</h5>
                <p>We look for temples that have fallen into disrepair and need restoration while preserving cultural and historical significance.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <div
              className="icon-bx-wraper text-center style-4 m-b30 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="icon-bx-sm m-b20">
                <a href="#" className="icon-cell">
                <img src="assets/img/mapping.png" alt="temple"></img>
                </a>
              </div>
              <div className="icon-content">
                <div className="separator" />
                <h5>Land surveys</h5>
                <p>Our team conducts detailed surveys of the land surrounding the temples, ensuring an in-depth understanding of the area and its needs.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <div
              className="icon-bx-wraper text-center style-4 m-b30 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="icon-bx-sm m-b20">
                <a href="#" className="icon-cell">
                <img src="assets/img/collaboration.png" alt="temple"></img>
                </a>
              </div>
              <div className="icon-content">
                <div className="separator" />
                <h5>Collaborate with local communities</h5>
                <p>We build strong partnerships with local communities, engaging and empowering them in the restoration process.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <div
              className="icon-bx-wraper text-center style-4 m-b30 wow fadeInUp"
              data-wow-delay="1.4s"
            >
              <div className="icon-bx-sm m-b20">
                <a href="#" className="icon-cell">
                <img src="assets/img/money.png" alt="temple"></img>
                </a>
              </div>
              <div className="icon-content">
                <div className="separator" />
                <h5>Secure funding</h5>
                <p>Through fundraising efforts and partnerships, we secure the funding needed to bring these projects to life, ensuring sustainable and long-term impact.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <div
              className="icon-bx-wraper text-center style-4 m-b30 wow fadeInUp"
              data-wow-delay="1.6s"
            >
              <div className="icon-bx-sm m-b20">
                {" "}
                <a href="#" className="icon-cell">
                <img src="assets/img/engineers.png" alt="temple"></img>
                </a>
              </div>
              <div className="icon-content">
                <div className="separator" />
                <h5>Work with civil engineers</h5>
                <p>
                Our skilled civil engineers design and develop structures that blend tradition with modern engineering, ensuring safety, durability, and respect for the original architecture.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6">
            <div
              className="icon-bx-wraper text-center style-4 m-b30 wow fadeInUp"
              data-wow-delay="1.6s"
            >
              <div className="icon-bx-sm m-b20">
                {" "}
                <a href="#" className="icon-cell">
                <img src="assets/img/future.png" alt="temple"></img>
                </a>
              </div>
              <div className="icon-content">
                <div className="separator" />
                <h5>Innovation and Restoration</h5>
                <p>We innovate with restoration techniques that respect the temple's heritage and introduce modern touches where appropriate, breathing new life into these sacred spaces.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="content-inner-1 section-wrapper3">
  <div className="container">
    <div
      className="section-head text-center wow fadeInUp"
      data-wow-delay="0.2s"
    >
      <h6 className="sub-title">Get Involved</h6>
      <h2 className="title">
      3 way you can help restore temples & protect dharma
      </h2>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
        <div className="icon-bx-wraper text-center style-5 m-b30">
          <div className="icon-lg m-b30">
            <a href="#" className="icon-cell">
            <img src="assets/img/donation2.png" alt="temple"></img>
            </a>
            <div className="badge">01</div>
          </div>
          <div className="icon-content">
            <h5 className="title">Donate to Temple Renovation Projects</h5>
            <p>
            Your donation helps restore sacred temples and preserve cultural heritage. Even small contributions go a long way in supporting repairs and maintenance.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
        <div className="icon-bx-wraper text-center style-5 m-b30">
          <div className="icon-lg m-b30">
            <a href="#" className="icon-cell">
            <img src="assets/img/help.png" alt="temple"></img>
            </a>
            <div className="badge">02</div>
          </div>
          <div className="icon-content">
            <h5 className="title">Volunteer</h5>
            <p>
            Offer your time and skills to help restore and maintain temples in need. From temple cleaning to structural repairs, your contribution can make a meaningful difference.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
        <div className="icon-bx-wraper text-center style-5 m-b30">
          <div className="icon-lg m-b30">
            <a href="#" className="icon-cell">
            <img src="assets/img/temple3.png" alt="temple"></img>

            </a>
            <div className="badge">03</div>
          </div>
          <div className="icon-content">
            <h5 className="title">Report Dilapidated Temples</h5>
            <p>
            Share details about neglected temples in need of restoration. Your information helps us identify and prioritize efforts to save these sacred sites.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center btn-bottom wow fadeInUp" data-wow-delay="0.8s">
      <a href="#" className="btn btn-primary">
        Start Now
      </a>
    </div>
  </div>

  {/* <img
    src="assets/images/side-images/shape3.png"
    alt="images"
    className="img-2"
  /> */}
</section>



{/* <section
  className="content-inner testimonial-wrapper2"
  style={{
    backgroundImage: 'url("assets/img/banner/ban7.jpg")',
    backgroundPosition: "center"
  }}
>
  <div className="container">
    <div
      className="section-head text-center wow fadeInUp"
      data-wow-delay="0.8s"
    >
      <h5 className="sub-title">Projects</h5>

      <h2 className="title">Current Temple Renovations & Projects</h2>
      <p>Explore our current Dharmic Initiatives located in Noida</p>
    </div>
  </div>
  <div className="min-container position-relative">
    <div className="swiper testimonial-swiper2">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="testimonial-2 wow fadeInUp" data-wow-delay="0.2s">
            <div className="testimonial-media">
              <img src="assets/images/testimonials/large/pic4.jpg" alt="" />
            </div>
            <div className="testimonial-content">
              <h5 className="testimonial-title">
                Heroes like you helped my son win his battle.
              </h5>
              <p className="testimonial-text text-dark">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look.{" "}
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#read"
                >
                  Read More
                </a>
              </p>
              <div className="testimonial-info">
                <div className="quotes">
                  <i className="fa-solid fa-quote-left" />
                </div>
                <div className="clearfix">
                  <h5 className="testimonial-name">Lindsay S.</h5>
                  <span className="testimonial-position">
                    Community Engagement
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="testimonial-2 wow fadeInUp" data-wow-delay="0.4s">
            <div className="testimonial-media">
              <img src="assets/images/testimonials/large/pic5.jpg" alt="" />
            </div>
            <div className="testimonial-content">
              <h5 className="testimonial-title">
                Heroes like you helped my son win his battle.
              </h5>
              <p className="testimonial-text text-dark">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look.{" "}
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#read"
                >
                  Read More
                </a>
              </p>
              <div className="testimonial-info">
                <div className="quotes">
                  <i className="fa-solid fa-quote-left" />
                </div>
                <div className="clearfix">
                  <h5 className="testimonial-name">Lindsay S.</h5>
                  <span className="testimonial-position">
                    Community Engagement
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="testimonial-2 wow fadeInUp" data-wow-delay="0.6s">
            <div className="testimonial-media">
              <img src="assets/images/testimonials/large/pic6.jpg" alt="image" />
            </div>
            <div className="testimonial-content">
              <h5 className="testimonial-title">
                Heroes like you helped my son win his battle.
              </h5>
              <p className="testimonial-text text-dark">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look.{" "}
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#read"
                >
                  Read More
                </a>
              </p>
              <div className="testimonial-info">
                <div className="quotes">
                  <i className="fa-solid fa-quote-left" />
                </div>
                <div className="clearfix">
                  <h5 className="testimonial-name">Lindsay S.</h5>
                  <span className="testimonial-position">
                    Community Engagement
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="swiper-btn swiper-btn-center-lr">
      <div className="test-swiper-prev btn-prev style-2">
        <i className="flaticon-left-arrow-1" />
      </div>
      <div className="test-swiper-next btn-next style-2">
        <i className="flaticon-next" />
      </div>
    </div>
  </div>
  <div className="container text-center m-t30">
    <a
      href="#"
      className="btn btn-secondary m-r15 m-b15"
    >
      Start A Fundraiser For Free
    </a>
    <a href="#" className="btn btn-dark m-b15">
      Talk To Us
    </a>
  </div>
</section> */}


<section
  className="content-inner-2 overlay-primary-light about-wrapper2 bg-img-fix"
  style={{
    backgroundImage: 'url("../assets/img/temple/12.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  }}
>
  <div className="container">
    <div className="about-bx2">
      <div className="row g-0">
        <div className="col-lg-4">
          <div className="dz-media">
            <img src="../assets/img/temple/bg.jpg" alt="image" />
          </div>
        </div>
        <div className="col-lg-8">
          <div className="dz-info">
            <h2 className="title m-b30 m-md-b20">
            Why Are Temples so Important in Sanatana Dharma?
            </h2>
            <p className="text">
            In ancient times, the temple was a place of dedication to education, music and dance, apart from fulfilling the spiritual needs of the individual. The temples of that time were like the community centers of today. Restoration and preservation of temples is rooted in our culture. They are symbols of faith that bring peace and have given a distinct identity to Mother India. Another significance of the temple is the structural representation of the human body. The temple represents the physical body and the heart is the sanctum sanctorum where we experience the presence of God.
            </p>
       
       <p>
       In ancient Indian texts, the temple is a place for pilgrimage. It is a sacred site whose atmosphere and design seek to symbolically condense the ideal principles of the Hindu way of life.All the cosmic elements that create and celebrate life in the Hindu pantheon are present in a Hindu temple - from fire to water, from images of nature to deities, from woman to man, from work to artha, from fleeting sounds to the smell of incense are part of Hindu temple architecture. - Chandrashekharendra Saraswati VIII
       </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<br/>
<br/>
<div className='text-center'>
  <h1>this is our project section</h1>
</div>




<br/>
<div className="call-action style-1 content-inner-1">
  <div className="container">
    <div
      className="inner-action overlay-primary-dark"
      style={{
        backgroundImage: 'url("../assets/img/temple/bg3.jpg")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className="row inner-content justify-content-center">
        <div className="col-xl-10 col-lg-9">
          <div className="section-head text-center">
            <h2 className="title text-white">
            Stay informed with our latest temple restoration progress and community updates
            </h2>
          </div>
      
        </div>
        <div className="col-xl-6 col-lg-9">
        <form className="dzSubscribe">
            <div className="dzSubscribeMsg text-white" />
            <div className="input-group">
              <input
                name="dzEmail"
                required="required"
                type="email"
                className="form-control"
                placeholder="Enter your email address..."
              />
              <div className="input-group-addon">
                <button
                  name="submit"
                  value="Submit"
                  type="submit"
                  className="btn btn-secondary btn-rounded-sm"
                >
                  <span>SUBSCRIBE</span>
                  <i className="fa-solid fa-paper-plane" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>



 </>
  )
}

export default Home
