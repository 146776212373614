import Home from "./components/Home";
import Layout from "./components/Layout";

function App() {
  return (
<>
<Layout>
  <Home/>
</Layout>
</>
  );
}

export default App;
